<template>
  <div class="room-directions">
    <div class="room-directions__content">
      <div class="room-directions__content__direction">
        <h1>{{showTTLock ? $t('room_directions.content.code_title') : $t('room_directions.content.title') }}</h1>

        <ProfileDetail />
        <LanguageSelect absolutePosition />

        <div :class="`room-number ${showTTLock && ttLockData.pincode ? 'pincode' : null}`">
          {{ showTTLock && ttLockData.pincode ? '*' + ttLockData.pincode + '#' : room.code }}
        </div>
        <div v-if="showTTLock" class="room-number-subtitle">{{ $t('room_directions.room.title') }}: {{ room.code }}</div>
        <div class="room-directions-info">
          <h2 v-if="roomDirections">{{ $t('room_directions.content.how_to_get_there') }}</h2>
          <p v-if="roomDirections">
            {{ roomDirections }}
          </p>
        </div>
      </div>

      <div class="room-directions__content__share">
        <h1>{{ $t('room_directions.content.more_information') }}</h1>

        <div v-if="phones.length > 0" class="phone-dropdown">
          <DropdownSelect name="phone"
            :value="phone"
            :options="phones"
            @change="(value) => phone = value"
          >
          </DropdownSelect>
        </div>

        <ButtonInput v-if="phones.length > 0"
          @click="logEvent('onSuccessIssueCardSentSmsClicked') & sendMessage"
          :loading="loading"
          :disabled="messageSent || loading"
          :style="{height: '3.125rem'}"
        >
          {{ $t('room_directions.content.send_sms') }}
        </ButtonInput>

        <div v-if="phones.length > 0" class="share-or-separator">
          <span><span>{{ $t('room_directions.content.or_separator') }}</span></span>
        </div>

        <div class="qr-code">
          <GuestWebQRCode :text="guestWebLink(property.pin, reservation.pin, true)"
            :logoSrc="mystayLogo"
          />
        </div>

        <div class="qr-code-info">
          <h2>{{ $t('room_directions.content.interest_in_extras') }}</h2>
          <p>
            {{ $t('room_directions.content.scan_qr_code_info') }}
          </p>
        </div>
      </div>
    </div>

    <div class="room-directions__actions">
      <ButtonInput @click="logEvent('onSuccessIssueCardIssueNextCardClicked') & $router.push({name: 'issue-card'})">
        {{ $t('room_directions.action.next_card') }}
      </ButtonInput>
      <ButtonInput @click="logEvent('onSuccessIssueCardLogOutClicked') & clearReservationStoreAndRedirect()">
        {{ $t('action.button.finish') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {
  UPDATE_RESERVATION,
  GET_ROOM_BY_ID,
  GET_RESERVATION_DETAIL,
  GET_CHECKINS,
  GET_TTLOCK_DATA,
} from '@/store/reservation';
import { GET_PROPERTY_DETAIL } from '@/store/property';
import LanguageSelect from '@/components/LanguageSelect/index';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import ProfileDetail from '@/components/ProfileDetail';
import DropdownSelect from '@/components/DropdownSelect';
import GuestWebQRCode from '@/components/GuestWebQRCode';
import { getTranslated } from '@/helpers/translations';
import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';
import api from '@/api/handler';
import flashMessage from '@/helpers/flashMessage';
import { guestWebLink } from '@/helpers/linkHelper';
import { mystayLogoBase64 } from '@/constants/image.base64';
import { logEvent } from '@/helpers/analytics';
import { TTLOCK as KEY_SYSTEM_TTLOCK } from '@/constants/keySystemType.js';

export default {
  name: 'RoomDirection',
  async mounted() {
    if (!this.reservation) {
      await this.$router.push('/home');
    }
  },
  components: {
    ButtonInput,
    LanguageSelect,
    ProfileDetail,
    DropdownSelect,
    GuestWebQRCode,
  },
  data() {
    return {
      roomId: this.$route.params.id,
      phone: null,
      loading: false,
      messageSent: false,
    };
  },
  computed: {
    ...mapGetters({
      getRoomById: GET_ROOM_BY_ID,
      reservation: GET_RESERVATION_DETAIL,
      checkIns: GET_CHECKINS,
      property: GET_PROPERTY_DETAIL,
      ttLockData: GET_TTLOCK_DATA,
    }),
    keyService() {
      return _.get(this.property, 'key_service');
    },
    showTTLock() {
      return this.keyService === KEY_SYSTEM_TTLOCK;
    },
    mystayLogo() {
      return mystayLogoBase64;
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    roomDirections() {
      return getTranslated(_.get(this.room, 'directions'), this.$i18n.locale);
    },
    phones() {
      const phones = {};
      if (this.reservation.phone) {
        phones[this.reservation.phone] = this.reservation.phone;
      }
      _.each(this.checkIns, (checkIn) => {
        phones[checkIn.phone] = checkIn.phone;
      });
      _.each(phones, (phone) => {
        if (!this.phone) {
          this.phone = phone;
        }
      });
      return phones;
    },
  },
  methods: {
    logEvent,
    guestWebLink: guestWebLink,
    sendMessage() {
      this.loading = true;
      api(this, endpoints.ENDPOINT_ROOM_DETAILS, methods.POST_ROOM_DETAILS_MESSAGE)(this.reservation.id, this.room.id, 'sms', this.phone)
        .then(({metadata}) => {
          if (metadata.success || metadata.success === 'true') {
            this.messageSent = true;
            setTimeout(() => {
              this.messageSent = false;
            }, 1000);
            flashMessage(this.$store, this.$t('room_directions.form.result.message_sent'), 'success');
          } else {
            flashMessage(this.$store, this.$t('room_directions.form.error.could_not_send'), 'danger');
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          flashMessage(this.$store, this.$t('room_directions.form.error.could_not_send'), 'danger');
        });
    },
    clearReservationStoreAndRedirect() {
      return this.$store.dispatch(UPDATE_RESERVATION, {})
        .then(() => {
          this.$router.push({name: 'home'});
        })
        .catch(() => {
          this.$router.push({name: 'home'});
        });
    },
  },
};
</script>

<style lang="scss">
  .room-directions {
    text-align: center;
    // width: calc(100% - 4rem);
    // max-width: 50rem;
    display: flex;
    flex-direction: column;

    &__content {
      display: flex;
      justify-content: space-around;
      margin-bottom: 1rem;

      &__direction {
        flex: 0 50%;
      }

      &__share {
        flex: 0 50%;

        select {
          width: 100%;
          max-width: 14rem;
          display: block;
          margin: 0 auto 0.5rem;
          height: 3rem;
          padding: 0 1rem;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: space-around;

      .button {
        flex: 0 50%;
        margin: 0 1rem;
      }
    }
  }

  .room-number {
    font-size: 5rem;
    font-weight: bold;
    line-height: 7.5rem;
    padding-bottom: 5px;

    &.pincode {
      font-size: 4rem;
      line-height: 5.5rem;
    }
  }

  .room-number-subtitle {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .qr-code {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 16rem;
    margin: auto;

    p {
      margin: 0 1rem 0 0;
    }

    svg {
      width: 5rem;
      height: 5rem;
      min-width: 5rem;
    }
  }

  .phone-dropdown {
    max-width: 12.5rem;
    margin: 0 auto .5rem;
  }

  .room-directions-info,
  .qr-code-info {
    h2 {
      margin-bottom: 0;
    }

    p {
      margin-top: .5rem;
    }
  }
</style>