<template>
  <div class="dropdown-select">
    <div class="dropdown-select__value"
      :class="{'open': open}"
      @click="openDropdown"
    >
      <span v-if="!!value">{{ value }}</span>
      <span v-else class="dropdown-select__value--placeholder">
        {{ placeholder || 'Please select' }}
      </span>
      <div class="dropdown-select__value__select"
        :class="{'dropdown-select__value__select--open': open}"
      >
        <CaretSvg />
      </div>
    </div>
    <div class="dropdown-select__selection" :class="{'open': open}">
      <div v-for="(phone, index) in options"
        :key="index"
        class="dropdown-select__selection__item"
        @click="() => selectPhone(phone)"
      >{{ phone }}</div>
    </div>
  </div>
</template>

<script>
import CaretSvg from '@/assets/images/icons/caret.svg';

export default {
  name: 'DropdownSelect',
  components: {
    CaretSvg,
  },
  data() {
    return {
      open: false,
    };
  },
  props: {
    value: {
      type: String,
      required: false,
    },
    options: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  methods: {
    openDropdown() {
      this.open = !this.open;
    },
    selectPhone(phone) {
      this.open = false;
      this.$emit('change', phone);
    },
  },
};
</script>

<style scoped lang="scss">
  .dropdown-select {
    position: relative;

    &__value {
      width: 100%;
      height: 3.125rem;
      border: solid 1px #e2e2e2;
      font-size: 1rem;
      line-height: 1rem;
      text-align: left;
      color: #4a4a4a;
      padding: 0 1rem;
      box-sizing: border-box;
      border: 1px solid #e2e2e2;
      background: #fff;
      -webkit-appearance: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @include border-radius(.5rem);

      &.open {
        @include border-radius-separate(.5rem, .5rem, 0, 0);
      }

      span {
        flex: 1;
      }

      &--placeholder {
        color: #4a4a4a;
      }

      &__select {
        transform: rotate(90deg);

        &--open {
          transform: rotate(-90deg);
        }

        svg {
          width: .5rem;
          height: .5rem;
        }
      }
    }

    &__selection {
      display: none;
      position: absolute;
      background: $white-color;
      width: 100%;
      border: 1px solid #e2e2e2;
      border-radius: 0 0 .5rem .5rem;
      text-align: left;
      z-index: 1;

      &.open {
        display: block;
      }

      &__item {
        padding: 1rem;
        border-bottom: 1px solid #e2e2e2;
        cursor: pointer;

        &:hover {
          background: #f9f9f9;
        }

        &:last-of-type {
          border-bottom: 0 none;
        }
      }
    }
  }
</style>